import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <div>
      Copyright &#169; 2024 Svetlana Baneva. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer
